// import { Algolia } from './../../../../../../../api/src/utils/algolia.class';
import { Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Router } from '@angular/router';
import * as algoliasearch from 'algoliasearch';
import { from as fromPromise, merge, Observable, Subject } from 'rxjs';
import { debounceTime, map, mergeMap, takeUntil } from 'rxjs/operators';
import { ICategory, IListing } from 'wz-types';

import { Globals } from '../../classes';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'wz-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SearchComponent implements OnInit, OnDestroy {
  @ViewChild(MatAutocompleteTrigger) searchAutoComplete: MatAutocompleteTrigger;
  @Input() mobile: boolean;
  algolia: algoliasearch.Client;

  destroy$: Subject<void> = new Subject();

  searchCategories$: Observable<ICategory[]>;
  searchListings$: Observable<IListing[]>;
  searchBoxClicked$ = new Subject();

  searchForm: FormGroup;

  searchPanelWidth = 0;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router
  ) { }

  ngOnInit() {
    this.setSearchPanelWidth();
    this.searchForm = this.formBuilder.group({
      searchText: [undefined]
    });

    this.algolia = algoliasearch(Globals.environment.algolia.appId, Globals.environment.algolia.publicKey);
    const categoriesIndex: algoliasearch.Index = this.algolia.initIndex(Globals.environment.development ? 'devCategories' : 'categories');
    const listingsIndex: algoliasearch.Index = this.algolia.initIndex(Globals.environment.development ? 'devListings' : 'listings');
    this.searchCategories$ = this.getSearchItems(categoriesIndex);
    this.searchListings$ = this.getSearchItems(listingsIndex);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  clearForm() {
    this.searchForm.reset();
  }

  searchTextCtrl() {
    return this.searchForm.get('searchText');
  }

  getSearchItems(algoliaIndex: algoliasearch.Index) {
    return merge(this.searchTextCtrl().valueChanges, this.searchBoxClicked$.pipe(map(() => this.searchTextCtrl().value))).pipe(
      debounceTime(250),
      mergeMap((text: string) => {
        const result: () => Observable<any[]> = () => fromPromise(algoliaIndex.search(text)).pipe(
          map((res: { hits: any[]; }) => res.hits)
        );
        return result();
      }),
      takeUntil(this.destroy$)
    );
  }

  async clickSearchBtn() {
    const txt = this.searchTextCtrl().value;
    if (!!txt && txt.length > 2) {
      this.searchAutoComplete.closePanel();
      await this.router.navigateByUrl(`search/${encodeURI(this.searchTextCtrl().value)}`);
      this.searchForm.reset();
    }
  }

  setSearchPanelWidth(): void {
    const screenWidth = Globals.screenWidth();
    let widthPx = 0;
    if (this.mobile && screenWidth < 600) {
      widthPx = screenWidth - 50;
    }
    this.searchPanelWidth = widthPx;
  }

  getImageUrl(item: any) {
    if (!(item.imageUrl || item.imgUrl)) {
      console.log('HERE', item);
    }
    return item.imageUrl || item.imgUrl;
  }
}
