// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const environment = {
  environmentName: 'development',
  siteUrl: 'https://wedzee-dev.firebaseapp.com',
  production: false,
  development: true,
  firebase: {
      config: {
          apiKey: 'AIzaSyBaSltvfMlIku7ez1kzwiesf71SvHnlft4',
          authDomain: 'Wedzee.com',
          databaseURL: 'https://wedzee-dev.firebaseio.com',
          projectId: 'wedzee-dev',
          storageBucket: 'wedzee-dev.appspot.com',
          messagingSenderId: '889088459859'
        }
  },
  recaptchaSiteKey: '6LcXzHMUAAAAAPNi_HhmggOGgI3aLOsTvV573v4g',
  functionsUrl: 'https://us-central1-wedzee-dev.cloudfunctions.net',
  apiUrl: 'https://wedzee-dev.appspot.com/api/',
  supportEmail: 'info@wedzee.com',
  supportPhone: '(863) 606-3927',
  streetAddress: {
    street1: '1002B S. Church Ave. 320791',
    city: 'Tampa',
    state: 'FL',
    zip: '33629'
  },
  algolia: {
    appId: 'PRMKC36TKH',
    publicKey: '8399fdc77c8043e2eca0f75ebc588864'
  },
  googlePlaces: {
    apiKey: 'AIzaSyBaSltvfMlIku7ez1kzwiesf71SvHnlft4'
  },
  stripe: {
    apiKey: 'pk_test_PxxNUONhVViqGeN6F0xwalL2',
    clientId: 'ca_DJANSsjH8zt3PZy4YD4kBBJS6Jp3sTuY',
    redirectUris: {
      sellerAccountCreated: 'https://wedzee-dev.firebaseapp.com/seller/home?newRegistration=true',
      existingSellerRegistered: 'https://wedzee-dev.firebaseapp.com/seller/stripe-registered'
    }
  },
  paypal: {
    sandboxClientId: 'AY_lbuA8fzyh5RbKqqfsMBxkgWXOieyHYr-UORKUsPoUF63r9bl-gl5JojSu_hP0fwFUuzYDeuJeBmsO',
    productionClientId: 'AcHUtrBx9xG8ti6AuxfJ4oT8JeJKBeycRPuhENmC-1MUcfFlerFGUsHxs2cx9z-aj7MPwsFNSARsnipb'
  },
  googleAnalytics: {
    trackingId: 'UA-97414347-2'
  },
  socialUrls: {
    facebook: 'https://www.facebook.com/Wedzee-136828397271779',
    twitter: 'https://twitter.com/shopwedzee',
    instagram: 'https://www.instagram.com/shopwedzee',
    pinterest: 'https://www.pinterest.com/161jc1eqpd5andg0u8g5c8d3fyl6l1'
  },
  taxjarApi: {
    version: '2022-01-24'
  },
  shippo: {
    carrier: 'shippo',
    tracking_number: 'SHIPPO_TRANSIT'
  }
};

export default environment;

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
